<template>
  <Head>
    <Title>{{ metaTitleDisplay }}</Title>
    <Meta name="description" :content="metaDescriptionDisplay"/>
    <Meta v-if="pageRobots" name="robots" :content="pageRobots"/>
    <Link rel="canonical" :href="linkCanonicalDisplay"/>

    <Meta property="og:site_name" content="MetaWin"/>
    <Meta property="og:type" content="website"/>
    <Meta property="og:url" :content="linkCanonicalDisplay"/>
    <Meta property="og:title" :content="ogTitleDisplay"/>
    <Meta property="og:description" :content="ogDescriptionDisplay"/>
    <Meta property="og:image" :content="ogImageDisplay"/>
    <Meta property="og:image:width" :content="ogImageWidthDisplay"/>
    <Meta property="og:image:height" :content="ogImageHeightDisplay"/>

    <Meta name="twitter:site" content="@Meta_Winners"/>
    <Meta name="twitter:creator" content="@Meta_Winners"/>
    <Meta name="twitter:card" content="summary_large_image"/>
    <Meta name="twitter:title" :content="metaTitleDisplay"/>
    <Meta name="twitter:description" :content="ogDescriptionDisplay"/>
    <Meta name="twitter:image" :content="ogImageDisplay"/>
    <Meta name="twitter:image:alt" :content="metaTitleDisplay"/>
    <Meta name="twitter:url" :content="linkCanonicalDisplay"/>
    <Meta name="twitter:cta" content="View on MetaWin"/>
    <component is="script" type="text/javascript" defer src="https://1862be5a-e657-43ea-bbca-a7cfc5e55fdd.snippet.anjouangaming.org/anj-seal.js"/>

    <slot/>
  </Head>
</template>

<script setup>
const props = defineProps({
  metaTitle: {
    type: String,
    required: true,
  },
  metaDescription: {
    type: String,
    default: null,
  },
  ogTitle: {
    type: String,
    default: null,
  },
  ogDescription: {
    type: String,
    default: null,
  },
  ogImage: {
    type: String,
    default: null,
  },
  ogImageWidth: {
    type: String,
    default: null,
  },
  ogImageHeight: {
    type: String,
    default: null,
  },
  removeTitleAppendix: {
    type: Boolean,
    default: false,
  },
  robots: {
    type: String,
    default: null,
  },
});

const { $config, } = useNuxtApp();
const route = useRoute();

const baseUrl = computed(() => $config?.public?.ENV_NAME === 'Prod' ? 'https://metawin.com' : 'https://dev.metawin.com');
const metaTitleDisplay = computed(() => {
  if (props.removeTitleAppendix) {
    return props.metaTitle || 'MetaWin';
  }
  return props.metaTitle ? `${props.metaTitle}` : 'MetaWin';
});
const ogTitleDisplay = computed(() => {
  if (props.removeTitleAppendix) {
    return props.ogTitle || 'MetaWin';
  }
  return props.ogTitle ? `${props.ogTitle} | MetaWin` : metaTitleDisplay.value;
});
const metaDescriptionDisplay = computed(() => props.metaDescription || 'The first decentralized NFT Competition marketplace. Adding utility to collections, allowing holders to win free NFTs and offering blue chips from Bored Ape Yacht Club and MoonBirds. Metawin.com - Winning in Web3');
const ogDescriptionDisplay = computed(() => props.ogDescription || 'The first decentralized NFT Competition marketplace. Adding utility to collections, allowing holders to win free NFTs and offering blue chips from Bored Ape Yacht Club and MoonBirds.');
const linkCanonicalDisplay = computed(() => `${baseUrl.value}${route.path === '/' ? '' : route.path}`);
const ogImageDisplay = computed(() => props.ogImage ? props.ogImage : `${baseUrl.value}/meta/metawin-og2.png`);
const ogImageWidthDisplay = computed(() => props.ogImageWidth ? props.ogImageWidth : '1200');
const ogImageHeightDisplay = computed(() => props.ogImageHeight ? props.ogImageHeight : '630');
const pageRobots = computed(() => props.robots || ($config.public.ENV_NAME === 'Prod' && window.location.host === $config?.public?.INDEX_DOMAIN ? 'index, follow' : 'noindex, nofollow'));
</script>
